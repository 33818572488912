import React from 'react';

function Projects({ goBack }) {
  return (
    <div className="section">
      <h2>Projects</h2>
      <p>Details about projects go here.</p>
      <button className="back-button" onClick={goBack}>Back</button>
    </div>
  );
}

export default Projects;
