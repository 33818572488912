import React from 'react';

function Skills({ goBack }) {
  return (
    <div className="section">
      <h2>Skills</h2>
      <p>Computer Vision/ML: PyTorch, TensorFlow, OpenCV, Detectron2, YOLO, MediaPipe, Ultralytics

Deep Learning: CNNs, Transformers, GANs, LSTM, Attention Networks, Transfer Learning

Full Stack: React, React Native, Next.js, Node.js, Express, FastAPI, Django

Databases: MongoDB, PostgreSQL, Redis, Elasticsearch, TimescaleDB

Cloud/MLOps: AWS (SageMaker, EC2, S3), Docker, Kubernetes, MLflow, DVC

Languages: Python, JavaScript/TypeScript, C++, CUDA, SQL

Tools: Git, CI/CD, Weights & Biases, Ray, NVIDIA TensorRT, OpenVINO</p>
      <button className="back-button" onClick={goBack}>Back</button>
    </div>
  );
}

export default Skills;
