import React from 'react';

function About({ goBack }) {
  return (
    <div className="section">
      <h2>About Me</h2>
      <p>
        I am a passionate Computer Science student at the University of Texas at Dallas with a strong foundation in programming, algorithms, and 
        machine learning and experienced in developing projects involving image processing, object
detection, and deep learning using PyTorch and OpenCV. Passionate about exploring innovative
applications of computer vision to solve real-world challenges and contribute to advancing AI
technologies.
      </p>
      <button className="back-button" onClick={goBack}>Back</button>
    </div>
  );
}

export default About;
