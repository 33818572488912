import React, { useState } from 'react';
import './App.css';
import About from './About';
import Projects from './Projects';
import Skills from './Skills';
import Contact from './Contact';

function App() {
  const [currentSection, setCurrentSection] = useState('hero');

  const renderSection = () => {
    switch (currentSection) {
      case 'about':
        return <About goBack={() => setCurrentSection('hero')} />;
      case 'projects':
        return <Projects goBack={() => setCurrentSection('hero')} />;
      case 'skills':
        return <Skills goBack={() => setCurrentSection('hero')} />;
      case 'contact':
        return <Contact goBack={() => setCurrentSection('hero')} />;
      default:
        return (
          <div className="hero">
            <h1>Aryan Mehta</h1>
            <nav className="hero-nav">
              <button onClick={() => setCurrentSection('about')}>About</button>
              <button onClick={() => setCurrentSection('projects')}>Projects</button>
              <button onClick={() => setCurrentSection('skills')}>Skills</button>
              <button onClick={() => setCurrentSection('contact')}>Contact</button>
            </nav>
          </div>
        );
    }
  };

  return <div className="app">{renderSection()}</div>;
}

export default App;
