import React from 'react';

function Contact({ goBack }) {
  return (
    <div className="section">
      <h2>Contact Me</h2>
      <p>Email: <a href="mailto:aryanmehta@example.com">aryanmehta@example.com</a></p>
      <button className="back-button" onClick={goBack}>Back</button>
    </div>
  );
}

export default Contact;
